<script setup lang="ts">
// vue
import { computed, ref } from 'vue'

// vuetify
import { useDisplay } from 'vuetify'

// types
import type { TutorPackageTranscript } from '@revolutionprep/types'

// nuxt
import { useRoute } from '#app'

// utilities
import { round } from '@revolutionprep/utils'
import { compareDesc, differenceInCalendarDays } from 'date-fns'

// components
import ItemSubject from './ItemSubject.vue'
import TableTutorMatches from './TableTutorMatches.vue'

// composables
import { useFormatDate } from '../../composables/formatDate'

/**
 * props
 * ==================================================================
 */
interface Props {
  tutorPackageTranscript: TutorPackageTranscript
}
const props = defineProps<Props>()

/**
 * vuetify
 * ==================================================================
 */
const { mdAndUp } = useDisplay()

/**
 * state
 * ==================================================================
 */
const showCompletedMatches = ref(false)

/**
 * composables
 * ==================================================================
 */
const { doFormatDate } = useFormatDate()

/**
 * route
 * ==================================================================
 */
const route = useRoute()

/**
 * computed
 * ==================================================================
 */
const additionalSubject = computed(() => {
  return props.tutorPackageTranscript.additionalSubject || null
})

const additionalSubjectDuration = computed(() => {
  return props.tutorPackageTranscript.additionalSubjectDuration || null
})

const additionalSubjectFrequency = computed(() => {
  return props.tutorPackageTranscript.additionalSubjectFrequency || null
})

const affiliateReservation = computed(() => {
  return props.tutorPackageTranscript?.affiliateReservation
})

const completedMatches = computed(() => {
  return matches.value
    .filter(match => match.state === 'completed')
    .sort((matchA, matchB) => {
      const createdA = new Date(matchA.createdAt)
      const createdB = new Date(matchB.createdAt)
      return compareDesc(createdA, createdB)
    })
})

const createdAt = computed(() => {
  return props.tutorPackageTranscript.createdAt
})

const daysUntilExpired = computed(() => {
  if (reservationExpiresAt.value) {
    return differenceInCalendarDays(
      new Date(reservationExpiresAt.value),
      new Date()
    )
  }
  return undefined
})

const daysUntilExpiredCopy = computed(() => {
  if (daysUntilExpired.value === 1) {
    return '1 day left'
  }
  return `${daysUntilExpired.value} days left`
})

const deliveredHours = computed(() => {
  return round(props.tutorPackageTranscript.deliveredDuration / 60)
})

const expirationClass = computed(() => {
  if (daysUntilExpired.value) {
    if (daysUntilExpired.value <= 0) {
      return ''
    }
    if (daysUntilExpired.value <= 7) {
      return 'short-expiration'
    }
    if (daysUntilExpired.value <= 30) {
      return 'medium-expiration'
    }
    if (daysUntilExpired.value > 30) {
      return 'long-expiration'
    }
  }
  return ''
})

const incompleteMatches = computed(() => {
  return matches.value.filter(match => match.state !== 'completed')
})

const matches = computed(() => {
  return props.tutorPackageTranscript.matches || []
})

const matchItems = computed(() => {
  return showCompletedMatches.value
    ? [...incompleteMatches.value, ...completedMatches.value]
    : incompleteMatches.value
})

const materials = computed(() => {
  return props.tutorPackageTranscript.materials || null
})

const notes = computed(() => {
  return props.tutorPackageTranscript.notes || null
})

const progressPercentage = computed(() => {
  return (deliveredHours.value / provisionedHours.value) * 100
})

const progressText = computed(() => {
  return `${deliveredHours.value} of ${provisionedHours.value} hours`
})

const provisionedHours = computed(() => {
  return round(props.tutorPackageTranscript.provisionedDuration / 60)
})

const reservationExpiresAt = computed(() => {
  return affiliateReservation.value?.expiresAt
})

const scheduledHours = computed(() => {
  return props.tutorPackageTranscript.scheduledDuration / 60
})

const showTutorMatches = computed(() => {
  return route?.query?.hideTutorMatches !== 'true'
})

const subject = computed(() => {
  return props.tutorPackageTranscript.subject || null
})

const subjectDuration = computed(() => {
  return props.tutorPackageTranscript.subjectDuration || null
})

const subjectFrequency = computed(() => {
  return props.tutorPackageTranscript.subjectFrequency || null
})

const subjectLabel = computed(() => {
  return subject.value && additionalSubject.value ? 'Subject 1' : 'Subject'
})

const title = computed(() => {
  return props.tutorPackageTranscript.name
})

const tutorMatchUrl = computed(() => {
  return `/tutor-packages/${props.tutorPackageTranscript.id}/matches`
})

const unscheduledHours = computed(() => {
  const unscheduledDuration =
    props.tutorPackageTranscript.provisionedDuration -
    props.tutorPackageTranscript.scheduledDuration
  return unscheduledDuration / 60
})

const unscheduleHoursExpiresCopy = computed(() => {
  const hoursCopy = unscheduledHours.value > 1
    ? `${unscheduledHours.value} hours `
    : `${unscheduledHours.value} hour `
  const expirationCopy = Number(daysUntilExpired.value) <= 0
    ? 'expired on'
    : unscheduledHours.value > 1
      ? 'expire on'
      : 'expires on'
  return hoursCopy + expirationCopy
})

/**
 * methods
 * ==================================================================
 */
function toggleCompletedMatches (value: boolean) {
  showCompletedMatches.value = value
}
</script>

<template>
  <v-expansion-panel>
    <v-expansion-panel-title class="d-flex align-start">
      <v-row>
        <v-col
          class="d-flex flex-column"
          cols="12"
          md="7"
          align-self="center"
        >
          <span>
            {{ title }}
          </span>
          <span
            v-if="affiliateReservation"
            class="pt-2"
          >
            Reservation Number: {{ affiliateReservation.reservationNumber }}
          </span>
        </v-col>
        <v-col
          cols="12"
          md="2"
          align-self="center"
          class="text-subtitle-2 text-grey py-0"
        >
          <div v-if="createdAt">
            <v-icon
              size="small"
              color="grey"
              class="me-1"
            >
              mdi-calendar-month
            </v-icon>
            <client-only>
              <span>
                {{ doFormatDate(new Date(createdAt), 'MMM d, yyyy') }}
              </span>
            </client-only>
          </div>
          <div class="d-flex">
            <v-icon
              size="small"
              :class="`me-2 ${expirationClass}`"
            >
              mdi-timer-sand
            </v-icon>
            <client-only>
              <span v-if="typeof daysUntilExpired === 'number'">
                <span
                  v-if="daysUntilExpired > 0"
                  :class="expirationClass"
                >
                  {{ daysUntilExpiredCopy }}
                </span>
                <span v-else-if="daysUntilExpired <= 0">
                  Expired
                </span>
              </span>
              <span v-else>
                Never expires
              </span>
            </client-only>
          </div>
        </v-col>
        <v-col
          :class="`d-flex ${
            mdAndUp ? 'flex-column justify-center align-center' : 'ps-1'
          }`"
          cols="12"
          md="3"
        >
          <div>
            <v-tooltip location="top">
              <template #activator="{ props: tooltipProps }">
                <v-chip
                  class="font-weight-bold"
                  v-bind="tooltipProps"
                >
                  <v-icon start>
                    mdi-clock-outline
                  </v-icon>
                  {{ progressText }}
                </v-chip>
              </template>
              <div class="d-flex flex-column">
                <span>
                  {{ scheduledHours }} hours scheduled
                </span>
                <span>
                  {{ unscheduledHours }} hours unscheduled
                </span>
                <span v-if="reservationExpiresAt">
                  {{ unscheduleHoursExpiresCopy }}
                  {{ doFormatDate(new Date(reservationExpiresAt), 'MMM d, yyyy') }}
                </span>
              </div>
            </v-tooltip>
          </div>
          <div
            v-if="unscheduledHours"
            class="pt-1 ms-2"
          >
            <v-chip
              color="error"
              label
              size="small"
              text-color="white"
            >
              <v-icon
                start
                size="small"
              >
                mdi-alert
              </v-icon>
              {{ unscheduledHours }} hours unscheduled
            </v-chip>
          </div>
        </v-col>
        <v-col
          v-if="progressPercentage < 100"
          cols="11"
        >
          <v-progress-linear
            :model-value="progressPercentage"
            height="12"
            color="info"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <ItemSubject
        v-if="subject"
        :subject="subject"
        :duration="subjectDuration"
        :frequency="subjectFrequency"
        :subject-label="subjectLabel"
      />
      <div v-if="additionalSubject">
        <v-divider class="mt-3 mb-1" />
        <ItemSubject
          :subject="additionalSubject"
          :duration="additionalSubjectDuration"
          :frequency="additionalSubjectFrequency"
          subject-label="Subject 2"
        />
      </div>
      <div v-if="notes">
        <v-divider class="mt-3 mb-2" />
        <v-row>
          <v-col cols="12">
            <div class="text-overline">
              Scheduling Notes
            </div>
            <v-alert
              variant="tonal"
              color="info"
              class="formatted-text"
            >
              {{ notes }}
            </v-alert>
          </v-col>
        </v-row>
      </div>
      <div v-if="materials">
        <v-divider class="mt-3 mb-2" />
        <v-row>
          <v-col cols="12">
            <div class="text-overline">
              Materials
            </div>
            <div>
              {{ materials }}
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="showTutorMatches">
        <v-divider class="mt-3 mb-3" />
        <v-row>
          <v-col
            cols="12"
            class="d-flex align-center"
          >
            <div class="text-overline">
              Tutor Matches
            </div>
          </v-col>
          <v-col
            v-if="matchItems.length || completedMatches.length"
            cols="12"
            class="pt-0"
          >
            <TableTutorMatches
              v-if="matchItems.length"
              :matches="matchItems"
              :tutor-match-url="tutorMatchUrl"
            />
            <div
              v-if="completedMatches.length"
              class="mt-2"
            >
              <v-btn
                v-if="!showCompletedMatches"
                color="secondary"
                size="small"
                variant="text"
                class="ms-n1 ps-1"
                @click.stop="toggleCompletedMatches(true)"
              >
                View completed matches
              </v-btn>
              <v-btn
                v-else
                color="secondary"
                size="small"
                variant="text"
                class="ms-n1 ps-1"
                @click.stop="toggleCompletedMatches(false)"
              >
                Hide completed matches
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<style scoped>
.formatted-text {
  padding-top: 0;
  white-space: pre-line;
  font-size: 14px;
}

.formatted-text:first-line {
  line-height: 0px;
}

.long-expiration {
  color: rgb(var(--v-theme-success));
}

.medium-expiration {
  color: rgb(var(--v-theme-yellow));
}

.short-expiration {
  color: rgb(var(--v-theme-error));
}
</style>
